import React from 'react'
import styled from 'styled-components'

import bgHeroXS from 'images/bg-hero-xs.svg'
import bgHeroMD from 'images/bg-hero-md.svg'
import bubbleTopXS from 'images/bubbles/top-xs.svg'
import bubbleTopMD from 'images/bubbles/top-md.svg'

import Container from 'components/container'

const Wrapper = styled.div`
  position: relative;
  height: 710px;
  min-height: 360px;
  max-height: 90vh;
  background-image: url(${bgHeroXS});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    background-image: url(${bgHeroMD});
    background-size: max(100%, 1000px) 100%;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;

    h1 {
      color: ${({ theme }) => theme.colors.white00};
      font-size: 30px;
      line-height: 1.34;
      font-weight: 800;
      padding: 0 16px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 48px;
      }
    }
  }

  #bubbleTopXS,
  #bubbleTopMD {
    position: absolute;
    top: 0;
    left: 0;
  }

  #bubbleTopXS {
    width: 100%;
    max-width: 500px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }

  #bubbleTopMD {
    display: none;
    width: 30%;
    min-width: 575px;
    height: 175px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      width: calc((100vw - 1140px) / 2 + 300px + 5vw);
    }
  }
`

const Hero: React.FC = () => {
  return (
    <Wrapper id="hero">
      <img src={bubbleTopXS} alt="" id="bubbleTopXS" />
      <img src={bubbleTopMD} alt="" id="bubbleTopMD" />
      <Container className="content">
        <h1>Build your product with our curated developers & designers</h1>
      </Container>
    </Wrapper>
  )
}

export default Hero
