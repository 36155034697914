import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Hero from 'components/home/hero'
import Services from 'components/home/services'

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Kanoonth" />
      <Hero />
      <Services />
    </Layout>
  )
}

export default IndexPage
